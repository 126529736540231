<mat-card appearance="outlined">
  <div class="action-container">
    <span class="warning lighter-text-2">
      This conversation is recorded and viewable by your school.
    </span>
    <div class="chat-actions">
      <button
        class="btn btn-light"
        (click)="onReport(true)"
        [disabled]="!hasId"
      >
        <mat-icon
          class="thumb-icon up"
          title="thumbs up icon"
          svgIcon="thumps-up-icon"
          [class.submitted]="thumbsupSubmitted"
        ></mat-icon>
      </button>
      <button
        class="btn btn-light"
        (click)="onReport(false)"
        [disabled]="!hasId"
      >
        <mat-icon
          class="thumb-icon down"
          title="thumbs down icon"
          svgIcon="thumps-down-icon"
          [class.submitted]="thumbsdownSubmitted"
        ></mat-icon>
      </button>
      <button
        class="btn btn-light feedback"
        (click)="onReportChat()"
        [disabled]="!hasId"
      >
        <i class="icon icon-b-comment"></i>
      </button>
    </div>
  </div>
  <mat-card-content>
    <div
      class="chat-container"
      [ngClass]="{ class: isClassChat, 'student-lyt': !!isStudentClassChat }"
    >
      <div #container class="chat-window">
        <div #chats *ngFor="let message of messagesCache">
          <div
            *ngIf="message.sender === 'Human'"
            [ngClass]="{
              ai: message.sender === 'AI',
              Human: message.sender === 'Human'
            }"
            class="message-container"
          >
            <div class="message-content">
              <div class="icon-container">
                <img src="../../assets/scss/icons/user.svg" alt="Me" />
              </div>
              <div class="message">{{ message.msg }}</div>
            </div>
          </div>
          <div
            *ngIf="message.sender === 'AI'"
            [ngClass]="{
              ai: message.sender === 'AI',
              Human: message.sender === 'Human'
            }"
            class="message-container"
          >
            <div class="message-content">
              <div class="icon-container bot">
                <img
                  class="chat-gpt-logo"
                  src="../../assets/scss/icons/bot.svg"
                  alt="Chat Icon"
                />
              </div>

              <div class="message">
                <markdown
                  clipboard
                  katex
                  class="variable-binding"
                  [data]="message.msg"
                ></markdown>
              </div>
            </div>
          </div>
          <div
            *ngIf="message.sender === 'System'"
            isAuthorizedToView
            [featureName]="'system-messages'"
            class="message-container"
          >
            <div class="message-content">
              <div class="icon-container">
                <img
                  class="chat-gpt-logo"
                  src="../../assets/scss/icons/system.svg"
                  alt="System"
                />
              </div>

              <div class="message">
                <markdown
                  clipboard
                  katex
                  class="variable-binding"
                  [data]="message.msg"
                ></markdown>
              </div>
            </div>
          </div>
        </div>
        <div class="loader">
          <loading-indicator class="v-h-center" [show]="isLoading" [size]="2">
          </loading-indicator>
        </div>
        <ng-container *ngIf="streamText && !selectedChat">
          <div class="message-container stream">
            <div class="message-content">
              <div class="icon-container">
                <img
                  class="chat-gpt-logo"
                  src="../../assets/scss/icons/bot.svg"
                  alt="Chat Icon"
                />
              </div>
              <div class="message">
                <markdown
                  clipboard
                  katex
                  class="variable-binding"
                  [data]="streamText"
                ></markdown>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="!selectedChat && !chatId">
        <form
          [formGroup]="chatInputForm"
          (ngSubmit)="sendMsg()"
          (keydown.enter)="$event.preventDefault(); sendMsg()"
        >
          <div class="form-group">
            <textarea
              formControlName="textInput"
              class="form-control"
              name="contentInput"
              cols="150"
              rows="2"
              placeholder="Send a Message"
            >
            </textarea>
            <br />
          </div>
        </form>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
