import { Component, OnDestroy, OnInit } from '@angular/core';
import * as reducers from '../../reducers';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuHidden = true;
  userId = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private store: Store<reducers.State>,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.store
      .select(reducers.getLoggedInUserInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userInfo) => {
        if (userInfo) {
          this.userId = userInfo.email || userInfo.fid;
        }
      });
  }

  onLogout(e: any) {
    this.authService.logout();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
